import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { getApiResponse } from "../../../services/ApiData.js";
import { useParams } from "react-router-dom";
// import { CometChat } from "@cometchat/chat-sdk-javascript";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import Paragraph from "../../atoms/Paragraph";
import {
  Col,
  Container,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import Icons from "../../atoms/Icons";
import { ConsultaService } from "../../../services/AdvogadoServices/ConsultasService";
import { ReagendarConsultaModal } from "../../molecules/ReagendarConsultaModal";
import { Toasts } from "../../utils/Toasts";
import { ConferenciaAdvogado } from "../../templates/ConferenciaAdvogado";
import { VideoConferenciaService } from "../../../services/VideoConferenciaServices/VideoConferenciaService";
import { ConsultasService } from "../../../services/ClienteServices/ConsultasService";
import CancelarConsultaAdvModal from "../CancelarConsultaAdvModal/index.js";
import CancelarConsultaCliModal from "../CancelarConsultaCliModal/index.js";

const ConsultaAgendadaCard = ({
  data,
  hora,
  nome,
  idConsulta,
  idCac,
  idAgenda,
  id,
  reagendar,
  cancelarAdv,
  cancelarCli,
}) => {
  const idAdv = id;
  const api = new ConsultaService();
  const apiModal = new ConsultasService();

  const data_do_dia = new Date();

  const ano = data_do_dia.getFullYear();
  const mes = String(data_do_dia.getMonth() + 1).padStart(2, "0");
  const dia = String(data_do_dia.getDate()).padStart(2, "0");

  const h = String(data_do_dia.getHours()).padStart(2, "0");
  const m = String(data_do_dia.getMinutes()).padStart(2, "0");

  const data_formatada = `${dia}/${mes}/${ano}`;
  const hora_formatada = `${h}${m}`;
  const hora_agenda = hora.replace(/:/g, "");

  const [showReagendarModal, setShowReagendarModal] = useState(false);
  const [showCancelarModal, setShowCancelarModal] = useState(false);
  const [showCancelarModalCli, setShowCancelarModalCli] = useState(false);

  const [agenda, setAgenda] = useState(null);

  function compareDates(dateStr1, dateStr2) {
    const date1 = parseDate(dateStr1);
    const date2 = parseDate(dateStr2);

    const yearComparison = date1.getFullYear() === date2.getFullYear();

    const monthComparison = date1.getMonth() === date2.getMonth();

    const dayComparison = date1.getDate() === date2.getDate();

    return yearComparison && monthComparison && dayComparison;
  }

  function parseDate(dateStr) {
    const [day, month, year] = dateStr.split("/").map(Number);

    return new Date(year, month - 1, day);
  }

  // Convertir les heures en minutes (en supposant que les heures sont au format "HHMM")
  const minutes_formatada =
    parseInt(hora_formatada.substring(0, 2)) * 60 +
    parseInt(hora_formatada.substring(2));
  const minutes_agenda =
    parseInt(hora_agenda.substring(0, 2)) * 60 +
    parseInt(hora_agenda.substring(2));

  // Vérifier si la différence est de 15 minutes
  const diffInMinutes = minutes_agenda - minutes_formatada;
  const isDifference15Minutes = Math.abs(diffInMinutes) === 15;

  const diffInHours = diffInMinutes / 60;
  const isDifferenceMoreThan5Hours = diffInHours > 5;

  // const api2 = useMemo(() => new VideoConferenciaService(), []);

  const plano = sessionStorage.getItem("plano");

  const params = useParams();
  const { typeUser } = params;

  const [appId, setAppId] = useState("");
  const [region, setRegion] = useState("");
  const [authKey, setAuthKey] = useState("");
  const [userLogged, setUserLogged] = useState(false);
  const [usersComet, setUsersComet] = useState([]);
  const [gruposComet, setGruposComet] = useState([]);
  const [showCometChat, setShowCometChat] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  function removeAccents(text) {
    const accents = "ÀÁÂÃÄÅàáâãäåÈÉÊËèéêëÌÍÎÏìíîïÒÓÔÕÖØòóôõöøÙÚÛÜùúûüÝýÿ";

    const accentsOut = "AAAAAAaaaaaaEEEEeeeeIIIIiiiiOOOOOOooooooUUUUuuuuYYy";

    return text
      .split("")
      .map((letter, index) => {
        const accentIndex = accents.indexOf(letter);
        return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
      })
      .join("");
  }

  const cli_id = sessionStorage.getItem("id");
  const emailLogado = sessionStorage.getItem("email");
  const telLogado = sessionStorage.getItem("tel");
  const tipoLogado = sessionStorage.getItem("tipo");
  const nomeLogado_1 = sessionStorage.getItem("nome");
  const nomeLogado_2 = nomeLogado_1.split(" ");
  const nomeLogado_3 = nomeLogado_2[0];
  const nomeLogado = (removeAccents(nomeLogado_3) + "_" + cli_id).toLowerCase();
  const nomeLigar_1 = nome.split(" ");
  const nomeLigar_2 = nomeLigar_1[0];
  const nomeLigar = (removeAccents(nomeLigar_2) + "_" + id).toLowerCase();

  const [grpo, setGrpo] = useState("");
  const [show, setShow] = useState(false);

  const [message, setMessage] = useState("");
  const [ativar, setAtivar] = useState(0);
  const [time, setTime] = useState();
  const [selectedDate, setSelectedDate] = useState({
    formDay: "",
    formMonth: "",
  });

  const defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    setOptionsToast(defaultOptionsToast);
  }

  const users = useCallback(async () => {
    try {
      setAtivar(1);
      let response = sessionStorage.getItem("usersComet");
      let response_grupo = sessionStorage.getItem("groupsComet");
      response = JSON.parse(response);
      response_grupo = JSON.parse(response_grupo);
      setUsersComet(response);
      setGruposComet(response_grupo);
    } catch (error) {
      console.error("problema de recuperar usuarios ou grupos", error);
    }
  }, [ativar]);

  useEffect(() => {
    users();
  }, []);

  const verifyUser = (username) => {
    setAtivar(2);
    return usersComet.some((user) => user.name === username);
  };

  const verifyGrupo = (groupName) => {
    return gruposComet.some((grupo) => grupo.name === groupName);
  };

  // const initializeCometChat = useCallback(async () => {
  //   const apiResponse = getApiResponse();
  //   try {
  //     if (!userLogged) {
  //       const loggedInUser = await CometChat.login(
  //         nomeLogado,
  //         apiResponse.authKey
  //       );
  //       setUserLogged(true);
  //     } else {
  //       console.log("User is already logged in:", nomeLogado);
  //     }
  //   } catch (error) {
  //     console.error("CometChat Error:", error);
  //   }
  // }, [userLogged, nomeLogado]);

  // useEffect(() => {
  //   initializeCometChat();
  // }, []);

  const videoConferencia = async () => {
  
    const dataAgendamento = new Date();

    dataAgendamento.setDate(data.split('/')[0]);
    dataAgendamento.setMonth(parseInt(data.split('/')[1]) - 1);
    dataAgendamento.setFullYear(data.split('/')[2]);

    dataAgendamento.setHours(parseInt(hora.split(':')[0]));
    dataAgendamento.setMinutes(parseInt(hora.split(':')[1]));
    dataAgendamento.setSeconds(0);

    dataAgendamento.setMinutes(parseInt(hora.split(':')[1]) - 10);

    // LIBERA VÍDEO CHAMADA COM 10 MINUTOS DE ANTECEDÊNCIA
    if (data_do_dia < dataAgendamento) {

      let optionsToast = {
        code: '102',
        message: "A videochamada estará disponível 10 minutos antes da sua consulta!",
        visible: true,
      };
  
      setOptionsToast(optionsToast);

      return false;
    }

    dataAgendamento.setHours(parseInt(hora.split(':')[0]) + 2);
    dataAgendamento.setMinutes(parseInt(hora.split(':')[1]));

    // BLOQUIA VÍDEO CHAMADA APÓS 2HS DO AGENDAMENTO
    if (data_do_dia > dataAgendamento) {

      let optionsToast = {
        code: '102',
        message: "Vídeo chamada não está mais disponível!",
        visible: true,
      };
  
      setOptionsToast(optionsToast);

      return false;
    }
    
    setShowSpinner(true);
    const typeUser = sessionStorage.getItem("tipo") == "A" ? "adv" : "cli";

    window.location.href = `/meet/${typeUser}/${idCac}`

  };

  function formatDate(day, month) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();

    // Adiciona um zero à esquerda se o dia ou mês forem menores que 10
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    const formattedDate = `${year}/${formattedMonth}/${formattedDay}`;
    return formattedDate;
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleCancelarCli = async () => {
    setShowCancelarModalCli(true);
  }

  const handleCloseCancelarModalCli = () => {
    setShowCancelarModalCli(false);
  };

  const handleCancelarAdv = async () => {
    setShowCancelarModal(true);
  };
  
  const handleCloseCancelarModal = () => {
    setShowCancelarModal(false);
    
  };

  const fecharModal = () => {
    setShowCometChat(false);
  };

  const handleCloseModal = () => {
    setShowReagendarModal(false);
    
  };

  const handleShowReagendamentoModal = async () => {
    try {
      const response = await apiModal.listarDadosReagendamento(idAdv);
      setAgenda(response);
      setShowReagendarModal(true);
    } catch (error) {
      console.error("Erro ao obter dados de reagendamento:", error.message);
    }
  };

  return (
    <Container className="d-flex justify-items-center card-consulta ">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />

      <Modal show={showCometChat} size="lg">
        <Modal.Header closeButton onClick={() => fecharModal()}></Modal.Header>
        <Container
          className=" d-flex justify-items-center "
          style={{ padding: 0, overflow: "hidden" }}
        >
          {sessionStorage.getItem("tipo") == "A" ? (
            plano === 1 ? (
              <ConferenciaAdvogado
                salaVideo={true}
                salaChat={false}
                nomeLigar={nomeLigar}
                nomeGrupo={grpo}
                nomeLogado={nomeLogado}
              />
            ) : (
              <ConferenciaAdvogado
                salaVideo={true}
                salaChat={false}
                nomeLigar={nomeLigar}
                nomeGrupo={grpo}
                nomeLogado={nomeLogado}
              />
            )
          ) : (
            <ConferenciaAdvogado
              salaVideo={true}
              salaChat={false}
              nomeLigar={nomeLigar}
              nomeGrupo={grpo}
              nomeLogado={nomeLogado}
            />
          )}
        </Container>
      </Modal>

      <Container>
        <Row
          lg={12}
          className="shadow rounded border-start border-warning border-3"
        >
          <Col className="d-flex flex-row align-items-center gap-2 ">
            <Col lg={6} className="d-flex flex-column ms-2">
              <Col className="d-flex flex-row align-items-center gap-3">
                <Paragraph text={data} />
                <Col
                  lg={4}
                  className="border border-warning pt-1 px-2  d-flex rounded justify-content-center"
                >
                  <Paragraph text={`${hora}`} className="fw-bold" />
                </Col>
              </Col>
              <Col>
                <Col className="">
                  <ParagraphSmall text={`* Com: ${nome}`} />
                </Col>
              </Col>
            </Col>
            <Col lg={6} className="d-flex flex-column gap-2 custom-gap">
              <Col
                lg={12}
                className="d-flex align-items-center custom-gap cursor"
                onClick={() => videoConferencia()}
              >
                <Col
                  lg={2}
                  // className="cursor"
                  // onClick={() => videoConferencia()}
                >
                  {showSpinner ? (
                    <Spinner
                      animation="border"
                      role="status"
                      className="spinner-border text-primary"
                    >
                      <span></span>
                    </Spinner>
                  ) : (
                    <Icons type="video" />
                  )}
                </Col>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: 'black',
                    textDecoration: 'underline'
                  }}
                >
                  Chamada de vídeo
                </p>
                {/* <ParagraphSmall text="Chamada de vídeo" className="" /> */}
              </Col>
              <Col
                lg={8}
                className="d-flex align-items-center gap-3 custom-gap"
              >
                {reagendar !== false && (
                  <Col className="d-flex flex-row align-items-center ">
                    <Col
                      className="cursor"
                      lg={3}
                      onClick={() => {
                        handleShowReagendamentoModal();
                      }}
                    >
                      <Icons type={"reagendar"} />
                    </Col>
                    <Col lg={12}>
                      <ParagraphSmall text="Reagendar Consulta" />
                    </Col>
                  </Col>
                )}
              </Col>
              {cancelarAdv ? (
                <Col lg={8} className="d-flex align-items-center gap-3 ">
                  <Col
                    className="cursor"
                    lg={2}
                    onClick={() => {
                      handleCancelarAdv();
                    }}
                  >
                    <Icons type="cancelar" />
                  </Col>
                  <ParagraphSmall text="Cancelar" />
                </Col>
              ) : null}

              {cancelarCli ? (
                <Col lg={8} className="d-flex align-items-center gap-3 ">
                  <Col
                    className="cursor"
                    lg={2}
                    onClick={() => {
                      handleCancelarCli();
                    }}
                  >
                    <Icons type="cancelar" />
                  </Col>
                  <ParagraphSmall text="Cancelar" />
                </Col>
              ) : null}
            </Col>
          </Col>
        </Row>
      </Container>

      <CancelarConsultaAdvModal
        showModal={showCancelarModal}
        handleCloseModal={ handleCloseCancelarModal }
        idAgenda={idAgenda}
      /> 

      <CancelarConsultaCliModal
      showModal={showCancelarModalCli}
      handleCloseModal={handleCloseCancelarModalCli}
      idConsulta={idConsulta}
      idAdvogado={id}
      />

      <ReagendarConsultaModal
        show={showReagendarModal}
        onHide={handleCloseModal}
        agenda={agenda}
        data={data}
        hora={hora}
        nome={nome}
        idConsulta={idConsulta}
        id={id}
      />
    </Container>
  );
};

export default ConsultaAgendadaCard;
