import functions from "../../components/utils/functions";
import api from "../Api";

export class CadastroService {
  async getAll() {
    return await api
      .get("/", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getById(id) {
    return await api
      .post(
        "/contratos-id",
        { cnt_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async postAtualizar(tokenGoogleo) {
    return await api
      .post(
        "/contratos-atualizar",
        {
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async postCadastrar(nome, email, telefone, cpf, senha, confirma_senha) {
    return await api
      .post(
        "/web/usuario/cadastrar-cliente",
        {
          cpf: cpf,
          nome: nome,
          email: email,
          celular: telefone,
          senha: senha,
          confirma_senha: confirma_senha,
        },
        {
          headers: {
            "Content-type": "application/json",
            // Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async postReenviaCodigoValidacao(cli_id) {
    return await api
      .post(
        "/web/usuario/reenvia-codigo-verificador",
        { cli_id },
        {
          headers: {
            "Content-type": "application/json",
            // Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async getDocsById(id) {
    return await api
      .post(
        "/documentos-listar",
        { cnt_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async postValidaCodigoVerificador(id, codigo) {
    return await api
      .post(
        "/web/usuario/valida-codigo-verificador",
        { id, codigo },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async postEnviaCodigoValidacao() {
    return await api
      .post(
        "/cliente/envia-codigo-verificador",
        {},
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  // async postReenviaCodigoValidacao(cli_id) {
  //   return await api.post("/advogado/reenvia-codigo-verificador", { cli_id },
  //     {
  //       headers: {
  //         "Content-type": "application/json",
  //         Authorization: "Bearer " + sessionStorage.getItem("token"),
  //       },
  //     }).then((res) => res.data)
  //     .catch((error) => {
  //       if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
  //         functions.modalToken();
  //       }
  //     });
  // }
}
