import React, { useEffect, useState, useCallback } from "react";
import "./styles.css";
import Subtitle from "../../atoms/Subtitle";
import * as formik from "formik";
import * as yup from "yup";
import { LoginService } from "../../../services/AdvogadoServices/LoginService.js";
import InputMask from "react-input-mask";
import functions from "../../utils/functions";
import Btn from "../../atoms/Button";
import {
  Col,
  Container,
  FloatingLabel,
  Row,
  Form,
} from "react-bootstrap";
import { Toasts } from "../../utils/Toasts";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { UserService } from "../../../services/UserServices/UserService";
import { VideoConferenciaService } from "../../../services/VideoConferenciaServices/VideoConferenciaService";
import { RedefinirSenhaModal } from "../../molecules/RedefinirSenhaModal/index.js";

export const LoginAdvogadoForms = () => {

  const [tokenRecaptcha, setTokenRecaptcha] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    setTokenRecaptcha(await executeRecaptcha("submit"));
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const { Formik } = formik;
  let Api = new LoginService();
  let ApiEmail = new UserService();
  // const api2 = new VideoConferenciaService();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({});
  const [validated, setValidated] = useState(false);
  const [showRedefinirSenha, setShowRedefinirSenha] = useState(false);

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  let emptyJson = {
    status: false,
    msg: "",
    tokenRecaptcha: "",
    acessos: "",
    dados: "",
  };

  const schema1 = yup.object().shape({
    cpf: yup
      .string()
      .test("valid-cpf", "CPF inválido", function (value) {
        return functions.validarCPF(value);
      })
      .required("CPF é obrigatório"),
    senha: yup.string("Senha incorreta").required("Senha é obrigatório"),
  });

  const handleSubmit = (values) => {
    setFormValues((prevFormValues) => ({ ...prevFormValues, ...values }));
    setStep((prevStep) => prevStep + 1);
    setValidated(true);
  };

  useEffect(() => {
    if (step === 2) {
      handleReCaptchaVerify();
      Api.postLogin(formValues.cpf, formValues.senha)
        .then((data) => {
          setOptionsToast((prevOptions) => ({
            code: data.mensagem.codigo,
            message: data.mensagem.mensagem,
            visible: true,
          }));
          if (data.status === true) {
            sessionStorage.setItem("token", data.data.tokenId.dados);
            sessionStorage.setItem("email", data.data.email);
            sessionStorage.setItem("acessos", "");
            sessionStorage.setItem("nome", data.data.name);
            sessionStorage.setItem("id", data.data.id);
            sessionStorage.setItem("fotoPerfil", data.data.fotoPerfil);
            sessionStorage.setItem("plano", data.data.plano);
            sessionStorage.setItem(
              "emailValidado",
              data.data.verificaCadastro.emailValidado
            );
            sessionStorage.setItem(
              "etapa_1",
              data.data.verificaCadastro.etapa_1
            );
            sessionStorage.setItem(
              "etapa_2",
              data.data.verificaCadastro.etapa_2
            );
            sessionStorage.setItem(
              "etapa_3",
              data.data.verificaCadastro.etapa_3
            );
            sessionStorage.setItem(
              "etapa_4",
              data.data.verificaCadastro.etapa_4
            );
            sessionStorage.setItem(
              "etapa_5",
              data.data.verificaCadastro.etapa_5
            );
            sessionStorage.setItem(
              "advogadoAtivo",
              data.data.verificaCadastro.advogadoAtivo
            );
            sessionStorage.setItem(
              "boasVindas",
              data.data.verificaCadastro.boasVindas
            );
            sessionStorage.setItem("tipo", data.data.tipo);
            sessionStorage.setItem("logado", true);


            // api2.getUsers().then((data) =>{
            //   if (data.data){
            //     let usersComet = JSON.stringify(data.data);
            //     sessionStorage.setItem("usersComet", usersComet)
            //   } else {
            //     console.log("erro ao carregar usurio do cometchat")
            //   }
            // }).catch((error) => {
            //   console.error("Error ao carregar os dados", error);
            // });

            // api2.getGroup().then((data) =>{
            //   if (data.data){
            //     // console.log(data.data)
            //     let groupsComet = JSON.stringify(data.data);
            //     sessionStorage.setItem("groupsComet", groupsComet)
            //   } else {
            //     console.log("erro ao carregar Grupo do cometchat")
            //   }
            // }).catch((error) => {
            //   console.error("Error ao carregar os dados", error);
            // });

            navigate("/");
          } else {
            let optionsToast = {
              code: data.mensagem.codigo,
              message: data.mensagem.mensagem,
              visible: true,
            };
            setStep((prevStep) => prevStep - 1);
            setOptionsToast(optionsToast);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [step]);

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const openRedefinirSenhaModal = () => {
    setShowRedefinirSenha(true);
  };

  const fecharModal = () => {
    setShowRedefinirSenha(false);
  };

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <Formik
            key={step}
            validationSchema={schema1}
            onSubmit={(values) => {
              // Remover mascara antes de enviar info ao back
              const { cpf, ...rest } = values;
              const cpfSemMascara = functions.removeMascara(cpf);
              const valuesSemMascara = {
                ...rest,
                cpf: cpfSemMascara,
              };
              handleSubmit(valuesSemMascara);
            }}
            initialValues={{
              cpf: "",
              senha: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleReset,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="cpf">
                  <FloatingLabel label={"CPF"}>
                    <InputMask
                      mask="999.999.999-99"
                      value={values.cpf}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.cpf && errors.cpf ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="cpf"
                          value={values.cpf}
                          onChange={handleChange}
                          isValid={touched.cpf && !errors.cpf}
                          placeholder="CPF"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    {touched.cpf && errors.cpf && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.cpf}
                      </Form.Control.Feedback>
                    )}
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.cpf}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="senha">
                  <FloatingLabel label={"Senha"}>
                    <Form.Control
                      className={`input-register-login-facj ${
                        touched.senha && errors.senha ? "is-invalid" : ""
                      }`}
                      type={type}
                      name="senha"
                      value={values.senha}
                      onChange={handleChange}
                      isValid={touched.senha && !errors.senha}
                      placeholder="Senha"
                    />
                    {touched.senha && errors.senha && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.senha}
                      </Form.Control.Feedback>
                    )}
                    <span className="icon-password-facj" onClick={handleToggle}>
                      <Icon icon={icon} size={25} />
                    </span>
                  </FloatingLabel>
                </Form.Group>
                <Nav.Link onClick={() => openRedefinirSenhaModal()}>
                  Esqueceu sua senha?
                </Nav.Link>

                <Col className="btn-login-facj">
                  <Btn type={"secondary"} isSumbit={true} text="Entrar" />
                </Col>

              </Form>
            )}
          </Formik>
        );
      default:
        return null;
    }
  };

  return (
    <Container className="container-login-facj">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      <Row>
        <RedefinirSenhaModal
        onHide={fecharModal} 
        show={showRedefinirSenha}/>
      </Row>
        <Col>
          <Subtitle text={"Login"} />
          {renderForm()}
        </Col>
    </Container>
  );
};
